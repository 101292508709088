.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grabbing;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.main-container {
  display: flex;
}
main {
  padding: 10px;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: white;
  color: black;
    height: 100vh;
  overflow-y: auto;
  overflow-x: auto;
}

.top_section {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 15px 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}

.icon{
  justify-content: center!important;
  align-items: center!important;
  align-content: center!important;
  display: flex!important;
  width: 20px;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);
  color: white;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link {
  display: flex;
  color: black;
  gap: 10px;
  padding: 5px 7px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  border-right: 4px solid white;
  background: blue;
  transition: 0.1s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}


.active {
  border-right: 4px solid white;
  background: white
}
.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.link:hover >  * {
  color: white;
  fill: white;
}

a {
  text-decoration: none!important;
}

.menu {
  display: flex;
  color: black;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
/*   border-bottom: #fff 0.5px solid;
 */}


.borde-tarjeta {
  /* box-shadow: -1px 3px 6px 1px rgb(202 212 228); */
  box-shadow: 0px 2px 4px 1px rgb(169 164 164 / 68%);
    background-clip: border-box !important;
}

.bordecircular{
  border-radius: 7px;
}





/*-----------------------------*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.touppercase {
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .content {
    padding: 25px 0;
  }
}

.table> :not(:first-child) {
  border-top: none !important;
}

.cursor:hover {
  cursor: pointer;
}


/*INPUT FILE*/
.absolute {
  position: absolute;
}

.opacoUpload {
  opacity: 0 !important;
}

.opacoUpload:hover {
  opacity: 0.9 !important;
}

.form-group {
  text-align: left !important;
  margin-bottom: 12px;
}

.titulos {

  font-size: 18px !important;
  font-family: inherit
}



/*AUTOCOMPLETADO*/

.sugerencia {
  padding: 5px;
}


.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 5px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 14px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  max-height: 300px;
  overflow: auto;
  margin-top: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0px;
  cursor: pointer;
}

.react-autosuggest__input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}