

body {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-size: 12px !important;
  text-shadow: 0px .1px 0px rgba(0, 0, 0, 0.65);
  /*   letter-spacing: .2px;
 */
  height: 100vh;
}


* {
  font-size: 12px !important;
}

svg {
  width: 16px !important;
}

.svgIv {
  width: 16px !important;
  margin-right: 5px;

}

.faIv {
  font-size: 14px !important;
  margin-right: 5px;
}

.letra-blanca {
  color: white !important;
}


.buscarpacientelist {
  max-height: 300px;
  overflow-y: scroll;
}

.w150 {
  min-width: 150px !important;
}

.row>* {
  margin-left: 0 !important;
  margin-right: 0 !important;
}


.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.fijopanelleft {
  margin-left: 25% !important;
}

.header-fixed .header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  height: 10vh;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a {
  text-decoration: none !important;
  color: #100f0f !important;
  font-weight: 600;
}

.acordionBotonColorText {
  color: rgb(10, 10, 10) !important;
}

.borde-layout {
  box-shadow: -1px 3px 6px 3px rgb(202 212 228);
}

.borde-tarjeta {
  box-shadow: 1px 2px 4px 1px rgb(169 164 164 / 68%);
  background-clip: border-box !important;
  /*  box-shadow: 0 0 7px 0px rgb(187, 188, 191); */
}

.borde-modal {
  box-shadow: 0 12px 28px 0 rgb(202 212 228), 0 2px 4px 0 rgb(202 212 228),
    inset 0 0 0 1px rgb(202 212 228);
}

.borde {
  --T68779821: 0 1px 2px var(--shadow-2);
  --webkit-box-shadow: var(--T68779821);
  box-shadow: 0 1px 2px var(--shadow-2);
}

/*  .borde {
    box-shadow: 3px 3px 9px -6px rgb(89 97 103);
  } */
.zindex {
  z-index: 90 !important;
}

.card {
  border: none !important;
  border-radius: 10px !important;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #ebedf3;
  border-radius: 0rem;
}

.card-body {
  padding: 12px;
}

.card-header {
  padding: 12px;
  margin-bottom: 0;
  background-color: #f2eeee;
  border-bottom: 1px solid #ebedf3;
  color: #100f0f;
  box-shadow: -1px 3px 6px 1px rgb(202 212 228);
}

.bg-Black {
  background: rgb(42 46 51);
}

.bg-green {
  background: green !important;
  color: white !important;
  margin: 1px;
}

.bg-red {
  background: red !important;
  color: white !important;
  margin: 1px;
}

.bg-integralvet {
  /*background: #2196f3 !important;*/
  background-color: #007ee2;
  border-bottom: "1px solid #d8d8d8";
  color: white;
}

.bordecircular {
  border-radius: 7px;
}


.modal .modal-header>button {
  border: 1px solid black !important;
  padding: 0px !important;
  border-radius: 45px !important;
  background-color: white;
  color: black;
  width: 30px;
}

.modal-content {
  padding: 12px;
  border-radius: 7px;
  box-shadow: 0px 3px 8px 1px rgb(169 164 164 / 68%);
  background-clip: border-box !important;
}

.modal .modal-header .close span {
  display: block !important;
  color: black;
  font-size: 18px !important;
}

.modal {
  background: #fbfbfbe6;
  /*background: #fbfbfbe6;*/
}

.cabeceraNotify {
  padding: 0.5rem 1.75rem !important;
}

.notifygif {
  width: 100%;
  padding-right: 0.5px;
  padding-left: 0.5px;
}

/*LOGIN STYLES*/
.bg-size {
  background-size: 100%;
}

@media(min-width: 993px) {
  .offcanvas.offcanvas-right.offcanvas-on {
    -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    right: 0;
    left: auto;
    margin-top: 0px !important;
  }
}

@media (max-width: 992px) {
  .card {
    margin-top: 15px !important;
  }

  .bg-size {
    background-size: 55% !important;
  }

  .modal-dialog {
    max-width: 90% !important;
  }


}

@media (max-width: 662px) {


  .bg-size {
    background-size: 75% !important;
    margin-bottom: 10px;
  }

  .notifygif {
    width: 100%;
    padding-right: 90.5px;
    padding-left: 90.5px;
    margin-bottom: 12px;
  }

  .modal-dialog {
    max-width: 100% !important;
  }

  .fijo {
    position: inherit !important;
    height: 100% !important;
    z-index: 0
  }



  /*  .header-fixed .header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 97;
    height: auto!important;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  } */

}

.tarjetalogin {
  width: 35% !important;
}

@media (max-width: 991.98px) {
  .header-mobile-fixed .wrapper {
    padding-top: 0px
  }

  .movil {
    display: none !important;
  }

  .tarjetalogin {
    width: 90% !important;
  }


  /* .header-mobile-fixed .header-mobile {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3000;
  height: 55px;
  min-height: 64px;
} */

  .bg-size {
    background-size: 75% !important;
    margin-bottom: 10px;
  }

  .notifygif {
    width: 100%;
    padding-right: 90.5px;
    padding-left: 90.5px;
    margin-bottom: 12px;
  }

  .modal-dialog {
    max-width: 100% !important;
  }

  .fijo {
    position: inherit !important;
    height: 100% !important;
    z-index: 0 !important
  }

  .fijopanelleft {
    margin-left: 0 !important;
  }

}


/*Acordion estylos personalizados*/
.card-header-acordion {
  padding: 0rem 2.25rem;
  margin-bottom: 0;
  background-color: #e8f0fe;
  border-bottom: none;
  color: black !important;
  border-radius: none !important;
}

.card-header:first-child {
  border-radius: none !important;
}

.transicioncolor {
  background-color: #9b59b6;
  -webkit-transition: font-size 1s ease;
  transition: font-size 1s ease;
}

.negrita {
  font-weight: bold !important;
}

.card.card-custom>.card-body {
  padding: 0;
}

.btn {
  border-radius: none !important;
}

.btn-menu-personalizado {
  display: inline-block;
  font-weight: normal;
  color: #3f4254;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: none;
}

.btn {
  display: inline-block;
  font-weight: normal;
  color: #3f4254;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0rem;
  -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    -webkit-box-shadow 0.3s ease-in-out;
}


/* .header-fixed .wrapper {
    padding-top: 51px !important;
  } */


.bordetr {
  box-shadow: 0px 2px 2px 0px rgb(202 212 228);
  cursor: pointer
}

.bordetr:hover {
  background-color: #e3e3e3;
}

#btn-0:hover {
  background-color: #f5f4f4 !important;
}

#btn-1:hover {
  background-color: #f5f4f4 !important;
}

#btn-2:hover {
  background-color: #f5f4f4 !important;
}

#btn-3:hover {
  background-color: #f5f4f4 !important;
}

#btn-perfil1:hover {
  background-color: red !important;
}

.container {
  max-width: 100% !important;
}





.fijo {
  position: fixed;
  height: 100%;
  background: none;
  border-right: 1px solid #80808042;
}

.titulosSize {
  font-size: 16px;
  font-weight: bold;
  color: grey;
}

.menuitemsize {
  font-size: 17px !important;
  font-weight: bold;
  margin-left: 12px;
  text-decoration: none;
  color: black;
  float: left;
}

.floatderecha {
  float: right;
}

.floatizquierda {
  float: left;
}



.initspan {
  background: none;
  color: black;
}

.menuspam {
  color: red;
}

/*=================*/
.custom-switch.custom-switch-sm .custom-control-label::after {
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  border-radius: calc(1rem - (1rem / 2));
}

.custom-switch.custom-switch-sm .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(calc(1rem - 0.25rem));
}

/* for md */

.custom-switch.custom-switch-md .custom-control-label {
  padding-left: 2rem;
  padding-bottom: 1.5rem;
}

.custom-switch.custom-switch-md .custom-control-label::before {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.custom-switch.custom-switch-md .custom-control-label::after {
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  border-radius: calc(2rem - (1.5rem / 2));
}

.custom-switch.custom-switch-md .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(calc(1.5rem - 0.25rem));
}

/* for lg */

.custom-switch.custom-switch-lg .custom-control-label {
  padding-left: 3rem;
  padding-bottom: 2rem;
}

.custom-switch.custom-switch-lg .custom-control-label::before {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.custom-switch.custom-switch-lg .custom-control-label::after {
  width: calc(2rem - 4px);
  height: calc(2rem - 4px);
  border-radius: calc(3rem - (2rem / 2));
}

.custom-switch.custom-switch-lg .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(calc(2rem - 0.25rem));
}

/* for xl */

.custom-switch.custom-switch-xl .custom-control-label {
  padding-left: 4rem;
  padding-bottom: 2.5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::before {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::after {
  width: calc(2.5rem - 4px);
  height: calc(2.5rem - 4px);
  border-radius: calc(4rem - (2.5rem / 2));
}

.custom-switch.custom-switch-xl .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(calc(2.5rem - 0.25rem));
}

/*STYLOS IMAGENES*/
.containerImg {
  position: relative;
  width: 100%;
}

.imageImg {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middleImg {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  right: -41%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.containerImg:hover .imageImg {
  opacity: 0.3;
}

.containerImg:hover .middleImg {
  opacity: 1;
}

.textImg {
  background-color: #0446aa;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

/* .bgfondo {
  background-color: #f0f2f5;
} */

.negrita {
  font-weight: bold;
}

.bordebottom {
  border-bottom: 1px solid #f0f2f5 !important;
}

.bordetop {
  border-top: 1px solid #f0f2f5;
}

.textTitleFondo {
  font-weight: 500;
}

.badgevet {
  margin-top: -13px;
  position: fixed;
  margin-left: -4px;
  padding: 6px;
  width: 23px;
  background: red;
  color: white;
  font-weight: bold;
  font-size: 14px;
}


.inputIcon {
  width: 15%;
  height: 39px;
  box-shadow: 0px 0px 1px 1px #00000036;
  z-index: 10;
}

.inputIconText {
  max-width: 20%;
  width: 20%;
  height: 39px;
  box-shadow: 0px 0px 1px 1px #00000036;
  z-index: 10;
}

.inputtext {
  width: 100%;
  padding: 12px;
  text-decoration: none;
  background: #f0f2f5 !important;
  border-radius: 5px;
  height: 41px !important;
  overflow: auto;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
}

.inputselect {
  width: 100%;
  padding: 12px;
  text-decoration: none;
  background: #f0f2f5 !important;
  border-radius: 5px;
  height: 45px !important;
  overflow: auto;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
}

.textareapublicar {
  width: 100%;
  padding: 12px;
  text-decoration: none;
  background: #f0f2f5 !important;
  border-radius: 5px;
  height: 70px !important;
  overflow: auto;
  border: none;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
}

.borde-tarjetapublicacion {
  box-shadow: 0px 3px 8px 1px rgb(169 164 164 / 68%);
  background-clip: border-box !important;
}


.selector {
  /* definir una altura pequeña para forzar el scroll */
  height: 100px;
  overflow-y: scroll;
  width: 400px;

  /* cambiar el estilo por defecto de la barra de scroll */
  scrollbar-color: black white;
  scrollbar-width: 25px;
}

div::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

div::-webkit-scrollbar-thumb {
  background-color: grey;
  outline: 0px solid white;
  border-radius: 30px;
}

@media (min-width: 992px) {
  .header-fixed .wrapper {
    padding-top: 60px !important;
  }



}

@media (max-width: 425px) {

  .offcanvas {
    width: 100% !important;
  }
}



.legend {
  display: block;
  width: auto;
  max-width: 100%;
  min-width: 250px;
  padding: 8px 6px;
  /*  margin: 0px!important; */
  /* margin-bottom: .5rem; */
  /* margin-left: 5px; */
  font-size: 1.5rem;
  line-height: inherit;
  color: black;
  font-weight: bold;
  white-space: normal;
  background-color: white;
  margin-top: -30px !important;
  background-color: #ffff;
  background-color: #fbfbfbe6;


}

.fieldset {
  min-width: 100%;
  padding: 12px;
  margin: 0;
  border-style: double;
  background-color: #fbfbfbe6;

}

.bad {
  position: absolute !important;
  top: 2px !important;
  margin-left: 10px;
  height: 23px;
  background-color: #2196F3;
  color: white !important;
}

.muted>* {
  color: #B5B5C3;
}

.badge-danger {
  color: #ffffff;
  background-color: red;
}

.badge-success {
  color: #ffffff;
  background-color: green;
}

.acomodate {
  width: 230px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chat_submit_box {
  bottom: 12px !important;
  box-sizing: border-box;
  left: 0;
  overflow: hidden;
  padding: 2px;
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  background-color: white;
}


.borde-tarjeta-login {
  box-shadow: 0px 3px 8px 1px rgb(169 164 164 / 68%);
  background-clip: border-box !important;
}

.toast-error {
  border: none !important;
  background-color: rgb(247, 108, 108) !important;
  text-align: center;
  margin: 0;
}

.tituloestilo {
  font-family: 'Billabong', sans-seri !important;
  font-size: 3.5rem !important;
}

.tituloestilo:hover {
  color: #7ac0f7
}


.desc {
  overflow: hidden;
  font-size: 1rem;
  line-height: 20px;
  color: #737373;
  text-overflow: ellipsis;
}

.novisto {
  background-color: #e7e7e7a3 !important;
}

.visto {
  background-color: white !important;
}


.topContainer {
  display: -webkit-box;
  flex-direction: row;
  overflow: auto;
  max-width: 440px !important;
  margin-left: 5px;
  margin-bottom: 12px;
}

.wraper {
  flex-wrap: wrap;
  max-width: 120px;
  min-width: 120px;
}

.textoPerfil {
  font-size: 12px;
  text-align: left;
}


.btn-primary-vet {
  background-color: #5ee1e6 !important;
  border-color: #5ee1e6 !important;
}

.text-btn {
  font-size: 15px !important;
}

.borde-botones {
  box-shadow: 4px 4px 3px 0px rgba(9, 9, 9, 0.35);
  background-clip: border-box !important;
}

.bg-btn-vet {
  padding: 8px 8px !important;
  border-radius: 100px !important;
  border: 1px solid #5ee1e6 !important;
  border-color: #5ee1e6 !important;
  background-color: #5ee1e6 !important;
  color: #4d4d4d !important;
  font-weight: bold !important
}

.menutable {
  border-radius: 3px;
  display: none;
  font-size: 13px;
  opacity: 0;
  padding: 8px 21px;
  position: fixed;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
  visibility: hidden;
  z-index: 999;
  max-width: 200px;
  word-wrap: break-word;
}

.contenedorMenu{
  width: 200px;
}

.showmenutable {
  opacity: 1;
  display: inline-block;
  margin-top: -10px;
  margin-left: -200px;
  visibility: visible;
  border: 1px solid;
}


.lista-menu{
  padding: 2px ;
}

.sizeInfoInput{
  font-size: 10px!important;
  color: #0446aa;
}
.bg-btn-vet-green {
  padding: 8px 8px !important;
  border-radius: 25px !important;
  border: 1px solid #18a186 !important;
  border-color: #18a186 !important;
  background-color: #18a186 !important;
  color: white !important;
  font-weight: bold !important
}

.bordecircular {
  border-radius: 7px;
}

.bordecircularpanel {
  border-radius: 20px;
  /*   min-height: 50%;
 */
}

.contentidopanel {
  min-height: 85vh !important;
  /*   height: 80vh!important;
 */
  margin-top: 12px;
  margin-bottom: 12px;
}

.bgfondo {
  /*   background-color: #f0f2f5;
 */
  background-color: #f3f3f3;
}

.bgfondodark {

  background-color: #f0f2f5
}

.titulosubmenusize {
  font-size: 18px !important;
}